class AutoFitText {
    constructor(el) {
        this.elements = this.getChildElementsWithText(el);
        this.elements = this.elements.map(e => {
            e.innerHTML = `<div class="af-container">${e.innerHTML}</div>`;
            e = e.querySelector('.af-container');
            return e;
        })
        this.elements.forEach(el => {
            this.init(el);
        })

        window.addEventListener('resize', this.onResize.bind(this));
        window.addEventListener('orientationchange', this.onResize.bind(this));
    }

    onResize() {
        this.elements.forEach(el => {
            this.fitFont(el);
        })
    }

    init(el) {
        this.fitFont(el);

        let attempts = 0;
        const interval = setInterval(() => {
            this.fitFont(el);
            if (attempts > 20) {
                clearInterval(interval);
            }
            attempts++;
        }, 100);
    }

    getChildElementsWithText(el) {
        const elements = [];
        const checkChild = (elemetns) => {
            for (let i = 0; i < elemetns.length; i++) {
                const element = elemetns[i];
                if (element.nodeType === Node.TEXT_NODE) {
                    elements.push(element.parentNode);
                } else {
                    checkChild(element.childNodes);
                }
            }
        }
        checkChild([el]);

        return Array.from(new Set(elements));
    }

    fitFont(el) {
        el.style.setProperty('font-size', '');
        el.style.setProperty('line-height', '');
        let fontFits = !this.checkOverflow(el);
        if (fontFits) { return; }


        let currentFontSize = parseFloat(getComputedStyle(el).fontSize);
        const lineHeight = this.getLineHeight(el) / currentFontSize;
        el.style.setProperty('line-height', lineHeight, 'important');
        while (!fontFits && currentFontSize > 8) {
            currentFontSize -= 0.1;
            el.style.setProperty('font-size', currentFontSize + 'px', 'important');
            fontFits = !this.checkOverflow(el);
        }
    }
    checkOverflow(el) {
        const curOverflow = el.style.overflow;
        if (!curOverflow || curOverflow === 'visible') {
            el.style.overflow = 'hidden';
        }
        const isOverflown = el.clientHeight < el.scrollHeight - 3 || el.clientWidth < el.scrollWidth;
        el.style.overflow = curOverflow;
        return isOverflown;
    }
    getLineHeight(el) {
        const temp = document.createElement(el.nodeName);
        let ret;
        temp.setAttribute('style', 'margin:0; padding:0; '
            + 'font-family:' + (el.style.fontFamily || 'inherit') + '; '
            + 'line-height:' + (el.style.lineHeight || 'inherit') + '; '
            + 'font-size:' + (el.style.fontSize || 'inherit'));
        temp.innerHTML = 'A';

        el.parentNode.appendChild(temp);
        ret = temp.clientHeight;
        temp.parentNode.removeChild(temp);
        return ret;
    }
}

class AutoFitTextWatcher {
    constructor() {
        document.querySelectorAll('[autoFitText]').forEach(el => {
            new AutoFitText(el);
        })
    }
}